@import "~react-image-gallery/styles/css/image-gallery.css";

.react-tagsinput {
  border-radius: 8px;
  padding-bottom: 3px !important;
  padding-top: 6px !important;
  height: 150px !important;
  overflow-y: auto !important;
}
.react-tagsinput-input {
  color: #000 !important;
  width: auto !important;
}
.react-tagsinput--focused {
  border: 1px solid #ccc !important;
}
.react-tagsinput-tag {
  border-radius: 8px !important;
  background-color: white !important;
  border: 1px solid #000 !important;
  color: #000 !important;
  padding: 3px 7px;
}

.MuiDivider-root {
  border-color: #eaecf0 !important;
}

.MuiInputBase-root.MuiOutlinedInput-root {
  border: #d0d5dd;
  border-radius: 8px;
}

.MuiInputBase-input {
  font-size: 14px !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  border: #d0d5dd !important;
  border-radius: 8px !important;
  padding: 10px 18px !important;
}

.MuiFormHelperText-root {
  margin-left: 0 !important;
}

.Mui-disabled {
  opacity: 0.5;
  border: none !important;
  color: #fff !important;
}

.MuiTextField-root {
  width: 100%;
}

.MuiButtonBase-root.MuiButton-root {
  padding: 10px 18px;
  border-radius: 8px;
  text-transform: none;
  font-family: IBM Plex Sans !important;
  /* width: 100% !important; */
}

.MuiButton-text {
  color: #004ccc !important;
  font-weight: 600 !important;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  background-color: #004ccc;
  border: 1px solid #004ccc;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-outlined {
  border: 1px solid #d0d5dd;
  background-color: #fff;
  color: #475467 !important;
}

.css-1wc848c-MuiFormHelperText-root {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.styles-module_wrapper__1I_qj {
  z-index: 99999 !important;
  background-color: rgba(0, 0, 0, 0.95) !important;
}

.siderOriginalClass {
  height: 250px !important;
  padding: 18px;
  object-fit: contain;
}
.image-gallery-left-nav,
.image-gallery-right-nav {
  bottom: -300px;
  transform: none;
  padding: 0;
  top: 0;
}
.image-gallery-svg {
  width: 20px !important;
  position: relative;
  bottom: -40px;
}
.image-gallery-image {
  border-radius: 8px;
}

.siderThumbnailClass {
  margin-top: 30px;
}

svg.sc-eqUAAy.bSdOrC.checklist-icon {
  width: 12px !important;
  position: relative !important;
  top: 4px !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Animate Current Asset Price After Placing Bid */
@keyframes animateCurrentPrice {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

@keyframes shareLink {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
